.banner {
  position: relative;

  margin-bottom: 10px;

  background-color: $color--blue-primary;
  background-image: url("/src/assets/images/mobile-wavy.png");
  background-repeat: no-repeat;
  background-position: center bottom;

  &::before {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 50%;
    bottom: 0;
    left: 0;

    display: block;

    content: " ";
    transform: matrix(-1, 0, 0, 1, 0, 0);

    opacity: 0.8;
    background: linear-gradient(270deg, #3c67d6 0%, rgba(60, 103, 214, 0) 107.16%);
  }

  &::after {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;

    display: block;

    content: " ";

    background: linear-gradient(270deg, #3c67d6 0%, rgba(60, 103, 214, 0) 107.16%);
  }

  .container {
    position: relative;
    z-index: 1;

    display: flex;
    // justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
      margin: 16px 0;

      text-align: center;

      color: white;

      font-size: 18px;
      font-weight: 600;
    }

    a.green-button {
      display: inline-block;

      margin-bottom: 16px;

      @include green-button;
    }
  }

  @media screen and (min-width: $tablet-min) {
    background-image: url("/src/assets/images/wavy.png");

    .container {
      align-items: center;
      flex-direction: row;
      justify-content: center;

      a.green-button {
        flex: 0 0 auto;

        margin-bottom: 0;
      }

      p {
        margin-right: 100px;

        text-align: left;
      }
    }
  }

  @media screen and (min-width: $desktop-min) {
    background-position-x: right;

    .container {
      padding: 16px 0;
    }
  }
}
