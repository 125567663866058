section.learnmore {
  margin: 80px auto;

  .learnmore-block {
    position: relative;

    display: flex;
    flex-direction: column;

    margin: 50px auto;
    padding: 36px 40px;

    border: 4px solid $color--grey-bg;
    border-top-right-radius: 60px;
    border-bottom-left-radius: 60px;

    &::before {
      position: absolute;
      top: 53px;
      left: -23px;

      display: block;

      width: 37px;
      height: 2px;

      content: " ";

      background-color: $color--blue-primary;
    }

    h2 {
      margin-bottom: 32px;
    }

    h4 {
      margin: 0 0 20px;

      font-family: Montserrat;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      line-height: 26px;
    }

    p {
      margin-top: 0;
    }

    .learnmore-link {
      position: relative;

      display: inline-block;
      align-self: flex-end;

      padding-right: 34px;

      text-decoration: none;

      color: $color--blue-primary;

      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      line-height: 20px;

      &::after {
        position: absolute;
        top: 50%;
        right: 0;

        width: 24px;
        height: 24px;

        content: " ";
        transition: transform 0.3s ease-out;
        transform: translateY(-50%);

        background-image: url("/src/assets/images/arrow.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }

      &:hover {
        &::after {
          transform: translateY(-50%) translateX(5px);
        }
      }
    }
  }

  @media screen and (min-width: $tablet-min) {
    .learnmore-block {
      padding: 48px 72px;

      &::before {
        top: 65px;
        left: -60px;

        width: 100px;
      }
    }
  }

  @media screen and (min-width: $desktop-min) {
    margin: 160px auto;

    .learnmore-block {
      max-width: 720px;
      padding: 48px 92px;
    }
  }
}
