@import "variables";
@import "mixins";

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('/src/assets/fonts/montserrat-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('/src/assets/fonts/montserrat-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/src/assets/fonts/montserrat-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/src/assets/fonts/montserrat-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/src/assets/fonts/montserrat-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/src/assets/fonts/montserrat-v14-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('/src/assets/fonts/montserrat-v14-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
       url('/src/assets/fonts/montserrat-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/src/assets/fonts/montserrat-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/src/assets/fonts/montserrat-v14-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/src/assets/fonts/montserrat-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/src/assets/fonts/montserrat-v14-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('/src/assets/fonts/montserrat-v14-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('/src/assets/fonts/montserrat-v14-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/src/assets/fonts/montserrat-v14-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/src/assets/fonts/montserrat-v14-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/src/assets/fonts/montserrat-v14-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/src/assets/fonts/montserrat-v14-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}

body,
html {
  overflow-x: hidden;

  min-height: 100%;
  margin: 0;
  padding: 0;

  color: $color--black;

  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 1.5;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body.locked {
  overflow-y: hidden;
}

.container {
  padding: 0 16px;

  @media screen and (min-width: $tablet-min) {
    max-width: 592px;
    margin: 0 auto;
  }

  @media screen and (min-width: $desktop-min) {
    max-width: 1120px;
  }
}

h1 {
  margin-top: 0;
  margin-bottom: 20px;

  font-size: 32px;
  font-weight: 700;
  line-height: 40px;

  @media screen and (min-width: $desktop-min) {
    margin-bottom: 24px;

    font-size: 40px;
    line-height: 48px;
  }
}

h2 {
  margin-top: 0;
  margin-bottom: 20px;

  font-size: 28px;
  font-weight: bold;
  line-height: 36px;

  @media screen and (min-width: $desktop-min) {
    margin-bottom: 24px;

    font-size: 36px;
    line-height: 47px;
  }
}

@import "header";
@import "hero";
@import "usage";
@import "features";
@import "clients";
@import "triggers";
@import "cas";
@import "pricing";
@import "banner";
@import "learnmore";
@import "footer";
