footer.main-footer {
  background-color: $color--blue-primary;

  .container {
    display: grid;

    padding-top: 20px;
    padding-bottom: 20px;

    color: white;

    row-gap: 32px;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto auto;
    grid-template-areas:
      "button"
      "logo"
      "main"
      "secondary"
      "info";
  }

  .green-button {
    @include green-button;
    grid-area: button;
  }

  .logo {
    grid-area: logo;
    svg {
      height: 40px;
      width: auto;
      .bar-bg,
      .text {
        fill: white;
      }
    }
  }

  .main-links,
  .secondary-links {
    ul {
      margin: 0;
      padding: 0;

      list-style-type: none;

      a {
        text-decoration: none;

        color: white;
      }

      li {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .main-links {
    grid-area: main;

    a {
      font-weight: 600;
    }
  }
  .secondary-links {
    grid-area: secondary;
  }

  .info {
    opacity: 0.7;
    color: $color--grey-bg;

    font-size: 12px;
    line-height: 18px;

    grid-area: info;
  }

  @media screen and (min-width: $tablet-min) {
    .logo {
      svg {
        height: 32px;
      }
    }

    .container {
      padding-top: 48px;
      padding-bottom: 48px;
      grid-template-rows: auto auto 40px auto;
      gap: 0 0;
      grid-template-columns: 48px 1fr 50px 1fr;
      grid-template-areas:
        "logo logo . secondary"
        ". main . secondary"
        ". . . ."
        ". info . button";
    }

    .green-button {
      align-self: center;
    }
  }

  @media screen and (min-width: $desktop-min) {
    .logo {
      svg {
        height: 40px;
      }
    }
    .container {
      grid-template-rows: auto auto;
      grid-template-columns: 56px 1fr 1fr 1fr;
      grid-template-areas:
        "logo logo secondary button"
        ". main secondary info";
    }

    .info {
      align-self: flex-end;
    }

    .green-button {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}
