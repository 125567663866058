.hero {
  position: relative;
  // overflow-x: hidden;
  // overflow-y: visible;
  // margin-top: 56px;

  padding-top: 370px;

  .blob {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    left: 0;

    width: 100%;
    height: 334px;

    border-bottom-left-radius: 100px;
    background-color: $color--blue-primary;
    // left: 50%;
  }

  .hero-phone {
    position: absolute;
    top: 59px;
    left: 50%;

    max-width: 100%;

    transform: translateX(-47%);

    img {
      width: 100%;
      max-width: 324px;
    }
  }

  .mobile-hero-balls-left {
    position: absolute;
    top: 82px;
    left: 0;
  }

  .mobile-hero-balls-right {
    position: absolute;
    top: 125px;
    // right: -82px;
    left: 50%;

    transform: translateX(44%);
  }

  .tablet-hero-balls-bottom {
    display: none;
  }

  .tablet-hero-balls-left {
    display: none;
  }

  .desktop-hero-balls-right,
  .desktop-hero-balls-left,
  .desktop-hero-balls-bottom {
    display: none;
  }

  .side-balls-left {
    display: none;
  }

  .hero-phone-tablet {
    display: none;
  }

  .hero-phone-desktop {
    display: none;
  }

  .try-now {
    max-width: 223px;

    @include blue-button;
  }

  .telegram-plane {
    display: none;
  }

  @media screen and (min-width: $tablet-min) {
    // padding-bottom: 200px;
    min-height: 610px;
    padding-top: 150px;

    .blob {
      left: 50%;

      width: 712px;
      height: 430px;

      transform: translateX(-150px);

      border-bottom-left-radius: 500px;
    }

    .side-balls-left {
      position: absolute;
      top: 367px;
      left: 50%;

      display: block;

      transform: translateX(-560px);
    }

    .hero-phone {
      display: none;
    }

    .mobile-hero-balls-left {
      display: none;
    }

    .telegram-plane {
      position: absolute;
      top: 103px;
      left: 50%;

      display: block;

      transform: translateX(-146px);
    }

    .hero-phone-tablet {
      position: absolute;
      top: 98px;
      left: 50%;

      display: block;

      transform: translateX(-20px);
    }

    .description {
      max-width: 272px;
    }

    .tablet-hero-balls-left {
      position: absolute;
      top: 118px;
      left: 50%;

      display: block;

      transform: translateX(-31px);
    }

    .tablet-hero-balls-bottom {
      position: absolute;
      top: 348px;
      left: 50%;

      display: block;

      transform: translateX(214px);
    }

    .mobile-hero-balls-right {
      top: 79px;

      transform: translateX(338px);
    }
  }

  @media screen and (min-width: $desktop-min) {
    height: 670px;
    padding-top: 130px;
    .blob {
      width: 100%;
      height: 500px;

      transform: translateX(-238px);
    }

    .mobile-hero-balls-left,
    .mobile-hero-balls-right,
    .tablet-hero-balls-bottom,
    .tablet-hero-balls-left {
      display: none;
    }

    .desktop-hero-balls-right,
    .desktop-hero-balls-left,
    .desktop-hero-balls-bottom {
      position: absolute;

      display: block;
    }

    .side-balls-left {
      transform: translateX(-960px);
    }

    .desktop-hero-balls-bottom {
      top: 366px;
      left: 50%;

      transform: translateX(324px);
    }

    .desktop-hero-balls-right {
      top: 87px;
      left: 50%;

      transform: translateX(456px);
    }

    .desktop-hero-balls-left {
      top: 164px;
      left: 50%;

      transform: translateX(-162px);
    }

    .hero-phone-tablet {
      display: none;
    }

    .hero-phone-desktop {
      position: absolute;
      top: 107px;
      left: 50%;

      display: block;

      transform: translateX(-76px);
    }

    .telegram-plane {
      transform: translateX(-236px);
    }

    .description {
      max-width: 350px;

      font-size: 18px;
    }
  }
}
