section.pricing {
  margin-top: 80px;
  background-color: $color--grey-bg;

  .container {
    padding-top: 48px;
    padding-bottom: 48px;

    h2 {
      margin-top: 0;
      text-align: center;
      margin-bottom: 40px;
    }
  }

  .prices {
    display: flex;
    flex-direction: column;
  }

  .price-card {
    background-color: white;
    border-radius: 30px;
    margin-bottom: 15px;
    padding-bottom: 10px;

    h3 {
      font-size: 24px;
      line-height: 31px;
      color: $color--green-primary;
      text-align: center;
      margin: 0;
    }

    .header {
      border-bottom: 2px solid $color--grey-bg;
      padding: 20px 40px;
      position: relative;

      &::after {
        content: " ";
        display: block;
        position: absolute;
        height: 4px;
        background-color: $color--blue-primary;
        border-radius: 2px;
        left: 50%;
        width: 33%;
        bottom: -3px;
        transform: translateX(-50%);
      }
    }

    .price {
      margin: 40px;
      // padding: 0 40px;
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 47px;
      text-align: center;
      white-space: nowrap;

      .suffix {
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #BDBDBD;
        white-space: nowrap;
      }
    }

    .bonuses {
      list-style-type: none;
      padding: 0 16px;
      margin: 40px auto;
      max-width: 260px;

      li {
        font-weight: 600;
        font-size: 16px;
        line-height: 21px;
        margin: 20px 0;
        padding-left: 36px;
        position: relative;

        &::before {
          content: " ";
          display: block;
          position: absolute;
          width: 20px;
          height: 20px;
          background-image: url("/src/assets/images/tick.svg");
          left: 0;
        }
      }
    }

    &.with-accent {
      background-color: $color--blue-primary;
      color: white;

      .header {
        border-color: rgba($color--grey-bg, 0.3)
      }

      .header::after {
        background-color: $color--white;
      }

      .price .suffix {
        color: rgba($color--grey-bg, 0.5)
      }
    }
  }

  @media screen and (min-width: $desktop-min) {
    .prices {
      flex-direction: row;

      .price-card {
        flex: 1;
        margin: 20px 0;

        &.with-accent {
          margin: 0 16px;
        }
      }
    }
  }
}