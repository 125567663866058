.main-header {
  position: fixed;
  z-index: 10;
  top: 0;
  right: 0;
  left: 0;

  height: 56px;
  padding: 14px 0;

  background-color: $color--blue-primary;

  & > div.container {
    display: flex;
    justify-content: space-between;
  }

  a.logo {
    display: block;

    height: 28px;

    text-decoration: none;

    svg {
      display: block;
      width: 139px;

      height: 28px;
      .text,
      .bar-bg {
        fill: white;
      }
    }
  }

  .menu {
    border: none;
    outline: none;
    background: none;

    svg {
      width: 24px;
      height: 24px;
      .menu-bar {
        transition: transform 0.2s ease-in-out;
        transform-origin: center;

        fill: white;
        will-change: transform;
      }
    }

    &.opened {
      svg > .first {
        transform: translateY(2px) rotate(45deg);
        // tran
      }
      svg > .second {
        transform: translateY(-2px) rotate(-45deg);
      }
    }
  }

  .links {
    position: fixed;
    // top: 56px;
    top: 120%;
    right: 0;
    bottom: 0;
    // height: calc(100vh - 56px);
    left: 0;

    display: flex;
    overflow-y: auto;
    flex-direction: column;
    justify-content: center;

    padding: 20px;

    transition: top 0.2s ease-in-out;

    background-color: $color--blue-primary;

    &.opened {
      top: 56px;
    }

    .main-links {
      display: flex;
      align-items: center;
      flex-direction: column;
      flex-grow: 1;
      justify-content: center;

      & > a.menu-link {
        display: block;

        margin: 10px 0;

        text-decoration: none;

        color: white;

        font-size: 24px;
        font-weight: bold;
        line-height: 31px;

        @include link-underline;
      }

      .signin-link {
        margin-top: 64px;

        & > a {
          width: 186px;

          font-size: 18px;

          @include green-button;
        }
      }
    }

    .extra-links {
      display: flex;
      justify-content: center;
      a {
        display: block;

        padding: 0 16px;

        transition: opacity 0.2s ease-in-out;
        text-align: center;
        text-decoration: none;

        opacity: 1;
        color: white;

        font-size: 18px;

        &:hover {
          opacity: 0.5;
        }
      }

      a:first-child {
        border-right: 1px solid white;
      }

      a:last-child {
        border-left: 1px solid white;
      }
    }
  }

  @media screen and (min-width: $tablet-min) {
    height: 68px;
    padding: 18px 0;

    background: none;
    transition: background .3s ease-in-out;
    transition: border-color .3s ease-in-out;


    border-bottom: 2px solid transparent;
    .menu {
      display: none;
    }

    a.logo {
      padding-right: 30px;

      svg {
        transform: translateX(-8px);
      }

      svg > .text {
        transform: translateX(-8px);

        fill: $color--blue-primary;
      }
    }

    .links {
      position: static;

      overflow-y: visible;
      flex-direction: row;
      flex-grow: 1;

      max-width: 685px;
      padding: 0;

      background: none;

      .main-links {
        flex-direction: row;

        a.menu-link {
          margin: 0 8px;

          font-size: 16px;
          font-weight: 600;
          transition: color .3s ease-in-out;
          // padding: 0 8px;
        }

        .signin-link {
          display: flex;
          flex-grow: 1;
          justify-content: flex-end;

          margin-top: 0;

          justify-self: right;

          // width: auto;

          & > a.button-green {
            width: auto;
            // padding: 0 8px;
            margin: 0 8px;
            padding: 0;
            // text-align: right;
            // justify-content: flex-end;

            transition: background-color 0s;
            transition: color .3s ease-in-out;

            color: white;
            background: none;
            border:none;
            font-size: 16px;
            font-weight: normal;
            line-height: 31px;

            @include link-underline;
          }
        }
      }

      .extra-links {
        display: none;
      }
    }

    &.fixed {
      background-color: white;
      border-bottom: 2px solid $color--blue-primary;
      // box-shadow: 2px 0 2px 2px rgba(0, 0, 0, 0.3);

      .links {
        a {
          color: $color--black !important;
        }
      }
    }
  }

  @media screen and (min-width: $desktop-min) {
    height: 72px;
    padding: 20px;

    .links {
      a {
        font-size: 18px;
      }

      .main-links {
        a.menu-link {
          margin: 0 20px;
        }
      }
    }
  }
}
