@mixin link-underline {
  position: relative;

  &::after {
    position: absolute;
    right: 0;
    bottom: -4px;
    left: 0;

    height: 4px;

    content: " ";
    border-radius: 2px;
    opacity: 0;
    background-color: $color--green-primary;
  }
  
  &:hover::after,
  &:focus::after,
  &.active::after {
    opacity: 1;
  }
}

@mixin button-base {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 16px 24px;

  transition: all 0.2s ease-in-out;
  text-align: center;
  text-decoration: none;

  border-radius: 4px;

  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  border-width: 2px;
  border-style: solid;
  white-space: nowrap;

  & > img {
    margin-left: 10px;
  }
}

@mixin green-button {
  color: $color--blue-primary;
  background-color: $color--green-primary;
  border-color: $color--green-primary;

  @include button-base();

  &:focus {
    border-color: $color--blue-hover;
  }

  &:hover {
    background-color: $color--green-hover;
    border-color: $color--green-hover;
  }
}

@mixin blue-button {
  color: $color--white;
  background-color: $color--blue-primary;
  border-color: $color--blue-primary;

  @include button-base();

  &:focus {
    border-color: $color--blue-hover;
  }

  &:hover {
    background-color: $color--blue-hover;
    border-color: $color--blue-hover;
  }
}

@mixin outline-button {
  color: $color--blue-primary;
  background: none;
  border-color: $color--blue-primary;

  @include button-base();

  &:focus {
    border-color: $color--blue-hover;
  }

  &:hover {
    color: $color--blue-hover;
    border-color: $color--blue-hover;
  }
}