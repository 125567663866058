.features {
  display: grid;

  margin-top: 80px;

  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    "analytics"
    "moderation"
    "management";
  gap: 16px;

  .feature-block {
    position: relative;

    padding: 36px 40px;

    .thumb {
      position: absolute;
      z-index: -1;
      top: -4px;
      right: -4px;
    }

    p {
      font-size: 14px;
      line-height: 21px;
    }

    ul {
      padding-left: 0;

      list-style-type: none;

      li {
        position: relative;

        margin: 16px 0;
        padding-left: 20px;

        vertical-align: middle;

        font-size: 16px;
        font-weight: 600;
        line-height: 21px;

        &::before {
          position: absolute;
          top: 6px;
          left: 0;

          display: block;

          width: 8px;
          height: 8px;

          content: " ";

          border-radius: 4px;
          background-color: $color--blue-primary;
          // margin-right: 12px;
          // ve
        }
      }
    }
  }

  .analytics {
    min-height: 420px;

    color: white;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background-color: $color--blue-primary;
    background-image: url("/src/assets/images/graph-bg.png");
    background-repeat: no-repeat;
    background-position: 25% bottom;

    grid-area: analytics;
  }

  .moderation {
    border: 4px solid $color--grey-bg;

    grid-area: moderation;
  }

  .management {
    border: 4px solid $color--grey-bg;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;

    grid-area: management;
  }

  @media screen and (max-width: 360px) {
    .feature-block {
      padding-top: 83px;
    }
  }

  @media screen and (min-width: $tablet-min) {
    .feature-block {
      padding: 48px 72px;
      .thumb {
        width: 140px;
        height: 140px;
      }
    }

    .analytics {
      min-height: auto;

      background-position-x: 195px;
      p {
        max-width: 272px;
      }
    }
  }

  @media screen and (min-width: $desktop-min) {
    margin-top: 160px;

    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      "analytics analytics"
      "moderation management";

    .feature-block {
      padding: 64px 92px;
      .thumb {
        width: 160px;
        height: 160px;
      }

      p {
        font-size: 16px;
        line-height: 24px;
      }

      ul li {
        font-size: 18px;
        line-height: 23px;
      }
    }

    .analytics {
      background-image: url("/src/assets/images/desktop-graph-bg.png");
      background-position: right bottom;

      p {
        max-width: 352px;
      }
    }

    .moderation {
      border-bottom-left-radius: 40px;
    }
    .management {
      border-bottom-left-radius: 0;
    }
  }
}
