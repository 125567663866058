.triggers {
  margin-top: 100px;

  background-color: $color--grey-bg;

  .container {
    display: flex;
    flex-direction: column;

    padding-top: 48px;
    padding-bottom: 48px;
  }

  .description {
    margin-bottom: 60px;
  }

  .blue-button {
    display: inline-block;

    width: auto;

    @include blue-button();
  }

  .trigger-example {
    width: 328px;
    height: 328px;
    margin: 0 auto;
    padding: 67px 20px 60px 55px;

    color: white;
    background-image: url("/src/assets/images/trigger-example.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;

    h3 {
      margin: 0;
    }

    pre {
      margin: 0;

      font-size: 12px;
    }
  }

  @media screen and (max-width: 360px) {
    .trigger-example {
      // transform-origin: 50% 50%;
      width: 288px;
      height: 288px;
      padding: 62px 20px 60px 50px;

      pre {
        font-size: 10px;
      }
    }
  }

  @media screen and (min-width: $tablet-min) {
    .container {
      flex-direction: row;
    }

    .description {
      flex: 1;
      order: 2;

      margin-bottom: 0;
      margin-left: 20px;

      .blue-button {
        display: inline-block;
      }
    }

    .trigger-example {
      flex: 0 0 288px;
      order: 1;

      width: 288px;
      height: 288px;
      padding: 62px 20px 60px 50px;

      pre {
        font-size: 10px;
      }
    }
  }

  @media screen and (min-width: $desktop-min) {
    .container {
      display: flex;
    }

    .description {
      margin-left: 150px;

      font-size: 18px;

      p {
        margin-bottom: 40px;
      }
    }

    // .description {
    //   order: 2;
    //   margin-bottom: 0;
    //   margin-left: 20px;
    // }

    .trigger-example {
      flex: 0 0 444px;
      // transform-origin: 50% 50%;

      width: 444px;
      height: 444px;
      padding: 90px 20px 60px 75px;

      pre {
        font-size: 16px;
      }
    }
  }
}
