.cas {
  margin-top: 80px;

  p {
    font-size: 14px;
    line-height: 21px;
  }

  ul {
    padding-left: 0;

    list-style-type: none;

    li {
      position: relative;

      margin: 16px 0;
      padding-left: 20px;

      vertical-align: middle;

      font-size: 16px;
      font-weight: 600;
      line-height: 21px;

      &::before {
        position: absolute;
        top: 6px;
        left: 0;

        display: block;

        width: 8px;
        height: 8px;

        content: " ";

        border-radius: 4px;
        background-color: $color--blue-primary;
        // margin-right: 12px;
        // ve
      }
    }
  }

  .buttons {
    margin-top: 40px;
    display: flex;
  }

  .blue-button {
    @include blue-button;
    flex: 1;
    margin-right: 8px;

    .extra {
      display: none;
    }
  }

  .outline-button {
    @include outline-button;
    flex: 1;
    margin-left: 8px;
  }

  img {
    height: 275px;
    display: block;
    margin: 0 auto;
  }

  @media screen and (min-width: $tablet-min) {
    position: relative;
    .buttons {
      display: block;
    }
    .blue-button, .outline-button {
      display: inline-block;

      .extra {
        display: inline;
      }
      // flex: auto;
    }
    
    img {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;;
    }

    ul {
      max-width: 50%;
    }

    p {
      max-width: 70%;
    }
  }

  @media screen and (min-width: $desktop-min) {
    p {
      font-size: 16px;
      line-height: 24px;
    }

    ul li {
      font-size: 18px;
      line-height: 23px;
    }

    img {
      height: 458px;
      left: 40%;
      top: 20px;
    }

    h2, ul, p {
      max-width: 35%;
    }
  }
}
