$tablet-min: 592px;
$desktop-min: 1120px;

$color--blue-primary: #3c67d6;
$color--blue-hover: #2e54b7;
$color--green-primary: #00d6af;
$color--green-hover: #00c9a7;

$color--black: #2f3442;
$color--grey-text: #bdbdbd;
$color--grey-dis: #d9d9d9;
$color--grey-bg: #f7f7f7;
$color--white: #ffffff;
