.usage {
  margin-top: 80px;
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  row-gap: 32px;
  grid-template-areas:
    "desc"
    "stats";

  .description {
    width: 100%;
    padding: 0 16px;

    grid-area: desc;
    justify-self: center;

    @media screen and (min-width: $tablet-min) {
      max-width: 592px;
    }

    @media screen and (min-width: $desktop-min) {
      max-width: 1120px;
    }

    p {
      margin-bottom: 0;
    }
  }

  .stats {
    padding: 32px 16px;

    background-color: $color--grey-bg;

    grid-area: stats;

    .wrapper {
      display: flex;
      justify-content: space-between;

      max-width: 377px;
    }

    .stat-block {
      .number,
      .text {
        display: block;
      }

      .number {
        color: $color--blue-primary;

        font-size: 18px;
        font-weight: 700;
      }

      .text {
        font-size: 14px;
      }
    }
  }

  @media screen and (min-width: $tablet-min) {
    grid-template-columns: 1fr 592px 1fr;
    grid-template-rows: auto auto;
    row-gap: 32px;
    grid-template-areas:
      "tmp desc none"
      "grey stats none";

    &::before {
      content: " ";

      background-color: $color--grey-bg;

      grid-area: grey;
    }

    .stats {
      padding: 36px 73px 36px 16px;

      border-top-right-radius: 130px;
      border-bottom-right-radius: 130px;
      .wrapper {
        max-width: initial;
      }
      .stat-block {
        .number {
          font-size: 24px;
        }
        .text {
          font-size: 16px;
        }
      }
    }
  }

  @media screen and (min-width: $desktop-min) {
    margin-top: 40px;

    grid-template-columns: 1fr 660px 460px 1fr;
    grid-template-rows: auto;
    grid-template-areas: "grey stats desc none";

    .description {
      margin-left: 76px;

      font-size: 18px;
    }

    .stats {
      padding: 48px 80px 48px 16px;

      border-top-right-radius: 130px;
      border-bottom-right-radius: 130px;

      .wrapper {
        max-width: initial;
      }
      .stat-block {
        .number {
          font-size: 32px;
        }
        .text {
          font-size: 18px;
        }
      }
    }
  }
}
