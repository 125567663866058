.clients {
  margin-top: 80px;
  h2 {
    text-align: center;
  }

  .client-list {
    display: block;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    margin-top: 40px;
    padding: 0;

    list-style-type: none;

    li {
      margin-bottom: 20px;
      padding: 0 8px;

      a {
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100%;
        padding: 12px 16px;

        border: 4px solid #f7f7f7;
        border-radius: 4px;

        img {
          display: block;

          height: 22px;
        }
      }
    }
  }

  @media screen and (min-width: $desktop-min) {
    margin-top: 160px;
    .client-list {
      li a {
        padding: 20px 36px;
      }
      li a img {
        height: 40px;
      }
    }
  }
}
